import Vue from "vue";
import App from "./App.vue";
import VueScrollTo from "vue-scrollto";
import VueSweetalert2 from "vue-sweetalert2";

import { store } from "./store";
import { auth } from "./utils/firebase";

import {
  Col,
  Row,
  Dropdown,
  Tabs,
  message,
  Menu,
  Input,
  Icon,
  Divider,
  Button,
  Select,
  Affix,
  DatePicker,
  Spin,
  Upload,
  Checkbox,
  PageHeader,
  Layout,
  FormModel,
  Radio,
  Tooltip,
  Statistic,
  Card,
  Switch
} from "ant-design-vue";

import router from "./router";

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.prototype.$message = message;

message.config({
  maxCount: 1
});

Vue.use(Col);
Vue.use(Row);
Vue.use(Dropdown);
Vue.use(Tabs);
Vue.use(Menu);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Divider);
Vue.use(Button);
Vue.use(Spin);
Vue.use(Select);
Vue.use(Affix);
Vue.use(DatePicker);
Vue.use(Checkbox);
Vue.use(Upload);
Vue.use(PageHeader);
Vue.use(Layout);
Vue.use(FormModel);
Vue.use(Radio);
Vue.use(Tooltip);
Vue.use(Statistic);
Vue.use(Card);
Vue.use(Switch);

Vue.config.productionTip = false;

Vue.use(VueScrollTo);
Vue.use(VueSweetalert2);

let app;

auth.onAuthStateChanged((user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }

  if(user) {
    store.dispatch('fetchUserProfile', user);
  }
});