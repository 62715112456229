import Axios from "axios";

const state = {};

const getters = {};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const actions = {
  async sendBook({ commit }, payload) {
    await Axios.post("https://apidevprod.cuy.pe/api/v1/claim-book/create", {
      ...payload
    })
      .then(response => {
        console.log("sendBook");
        console.log(commit, response);
      })
      .catch(error => {
        throw error;
      });
  },
  async sendForm({ commit }, { payload, fileList }) {
    console.log(commit);
    /*  const fakeData = {
      phone: "915181818",
      dni: "43299966",
      name: "Wilzon",
      lastName: "Mariño Bueno",

      calleAvJr: "Calle",
      nombreCalleAvJr: "La Libertad",
      numero: 176,
      departamento: "Lima",
      provincia: "Lma",
      distrito: "Miraflores",

      notificacionPorEmail: true,
      email: "wilzon@wimarbueno.com",

      enviarEmailConstancia: true,
      solicitudConcreta: "Devuélvanme mi dinero",
      materiaDeReclamo: "Facturación",

      tipoDeDocumento: "Recibo",
      numeroFacturaBoleta: "122",
      fechaDeEmisionDelDocumentoRecibo: "2019-11-22",
      concepto: "Cargo fijo"
    }; */

    await Axios.post("https://apidevprod.cuy.pe/api/v1/claim-book/claims-support", {
      ...payload
    })
      .then(async response => {
        await asyncForEach(fileList, async file => {
          let fd = new FormData();
          fd.set("moduleName", "Atencion_de_Reclamos");
          fd.append("file", file);
          await Axios.post(
            "https://apidevprod.cuy.pe/api/v1/zoho/crm/files-ttachments/upload/" +
              response.data.data.id,
            fd,
            {
              headers: { "Content-Type": "multipart/form-data" }
            }
          );
        });
      })
      .catch(error => {
        throw error;
      });
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  setUploadToken(state, token) {
    state.uploadToken = token;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
