import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as fb from '../utils/firebase';
import tab from "./tab.module";
import idtype from "./idtype.module";
import claims from "./claims.module";
import user from "./user.module";
import legales from "./legales.module";

Vue.use(Vuex);

fb.legalesCollection.orderBy('order', 'asc').onSnapshot(snapshot => {
  let legalesArray = [];

  snapshot.forEach(doc => {
    let post = doc.data();
    post.id = doc.id;

    legalesArray.push(post);
  });

  store.commit('setLegales', legalesArray);
});

export const store = new Vuex.Store({
  modules: {
    tab,
    idtype,
    claims,
    user,
    legales
  },
  plugins: [createPersistedState()]
});