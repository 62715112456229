import router from '../router/index';
import toaster from 'toastr';
import * as fb from '../utils/firebase';

const state = {
  userProfile: {}
};

const getters = {
  getUser: (state) => state.userProfile
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  setUserProfile(state, val) {
    state.userProfile = val;
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const actions = {
  login({ dispatch }, form) {
    // Sign in
    return new Promise((resolve, reject) => {
      fb.auth.signInWithEmailAndPassword(form.email, form.password)
        .then(response => {
          dispatch('fetchUserProfile', response.user);
          resolve(true)
        })
        .catch(error => {
          toaster.error(error.message);
          reject(error)
        });
    })
  },
  // Sign up
  /*signup({ dispatch }, form) {
    console.log(dispatch);
    return new Promise((resolve, reject) => {
      fb.auth.send(form.email, form.password)
        .then(({ user }) => {
          fb.usersCollection.doc(user.uid).set({
            firstname: form.firstname,
            lastname: form.lastname,
            admin: form.admin
          }).then(() => {
            dispatch('setUserProfile', user);
            toaster.success('User created');
            resolve(true);
          })
            .catch(error => {
              toaster.error(error.message);
              reject(error);
            })
        })
        .catch(error => {
          reject(error);
        });
    })
  },*/
  async logout({ commit }) {
    // sign out
    await fb.auth.signOut();

    // clear userProfile and redirect
    commit('setUserProfile', {});

    router.push('/login');
  },
  async fetchUserProfile({ commit }, user) {
    console.log(user);
    // fet profile
    const userProfile = await fb.usersCollection.doc(user.uid).get();

    // set state profile
    commit('setUserProfile', userProfile.data());

    // Redirect to dashboard
    if (router.currentRoute.path === '/login') {
      router.push('/dashboard/overview');
    }
  }
}

export default {
  state,
  actions,
  getters,
  mutations
};