<template>
  <div id="app">
    <helmet-provider>
      <div>
        <helmet>
          <meta charset="utf-8" />
          <title>eXIM | Legales</title>
        </helmet>
      </div>
    </helmet-provider>
    <router-view></router-view>
  </div>
</template>

<script>
import { Helmet, HelmetProvider } from "@jnields/vue-helmet";

export default {
  name: "app",
  components: { HelmetProvider, Helmet },
};
</script>

<style lang="less">
@import url("https://fonts.googleapis.com/css?family=Aldrich|Bungee|Bungee+Hairline&display=swap?css");
@import "https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.min.css";

#app {
  font-family: "Colfax", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .ant-tabs-nav-scroll {
    margin-left: 2rem;
  }
  .ant-tabs-tab:hover {
    color: #132d2f;
  }
  .ant-tabs-tab-active {
    color: #132d2f;
    font-weight: 600;
  }
  .ant-tabs-ink-bar {
    background: #f5b22f;
  }
  .static {
    h2 {
      color: #132d2f;
      font-family: "Bungee", cursive;

      &.title {
        
        color: #444;
        text-align: center;
      }
    }
  }
  .form,
  .ant-form {
    label {
      color: #444;
    }
    input,
    .ant-input,
    .ant-select-selection {
      border-color: #132d2f;
    }
  }
}
</style>