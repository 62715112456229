import Vue from "vue";
import VueRouter from "vue-router";

import { auth } from "../utils/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../screens/Login/Login.vue")
  },
  {
    path: "/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../screens/Signup/Signup.vue")
  },
  {
    path: '/password-reset',
    name: "Password Reset",
    component: () =>
      import(/* webpackChunckName: "passwordReset" */ "../screens/PasswordReset/PasswordReset")
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../screens/Dashboard.vue"),
    children: [
      {
        path: "overview",
        name: "overview",
        component: () =>
          import(/* webpackChunkName: "overview" */ "../screens/Overview/Overview.vue")
      },
      {
        path: "legales",
        name: "legales",
        component: () =>
          import( /* webpackChunkName: "legales" */ "../screens/Legales/Legales.vue"),
        children: [
          {
            path: "",
            name: "legales list",
            component: () =>
              import( /* webpackChunkName: "legales" */ "../screens/Legales/LegalesList.vue")
          },
          {
            path: "create",
            name: "legales create",
            component: () =>
              import( /* webpackChunkName: "legales-form" */ "../screens/Legales/LegalesForm.vue")
          },
          {
            path: ":id/edit",
            name: "legales edit",
            component: () =>
              import( /* webpackChunkName: "legales-form" */ "../screens/Legales/LegalesForm.vue")
          }
        ]
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/",

    component: () =>
      import(/* webpackChunkName: "login" */ "../screens/Home.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(/* webpackChunkName: "vision" */ "../screens/Vision/Vision.vue")
      },
      {
        path: "/consultas-lineas",
        component: () =>
          import(/* webpackChunkName: "lines-by-dni" */ "../screens/LinesByDni/LinesByDni.vue")
      },
      {
        path: '/atencion-reclamos',
        component: () =>
          import(/* webpackChunkName: "claims" */ "../screens/Claims/Claims.vue")
      },
      {
        path: "/libro-reclamaciones",
        name: "Complaints",
        component: () =>
          import(
            /* webpackChunkName: "complaints" */ "../screens/ComplaintsBook/ComplaintsBook.vue"
          )
      },
      {
        path: "/:slug",
        component: () =>
          import(/* webpackChunkName: "page" */ "../screens/Page.vue")
      }
    ]
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) {
    next('/login');
  } else {
    next();
  }
})

export default router;
