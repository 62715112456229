import Axios from "axios";

const state = {
  types: [],
  numbersList: [],
  isLoading: false,
  loading: false
};

const getters = {
  idTypes: state => {
    return state.types;
  },
  isLoading: state => {
    return state.isLoading;
  },
  numbersList: state => {
    return state.numbersList;
  },
  loading: state => {
    return state.loading;
  }
};

const actions = {
  async fetchTypes({ commit }) {
    commit("setIsLoading", true);

    const request = await Axios.get(
      "https://apidevprod.cuy.pe/api/v1/user/user-identification-type"
    );
    commit("setTypes", request.data.list);
    commit("setIsLoading", false);
  },
  async getNumbersByDni({ commit }, payload) {
    payload.whiteBrand = 7;
    commit("setLoading", true);
    await Axios.post(
      "https://apidevprod.cuy.pe/api/v1/user/list-phone-by-dni-public",
        {
          ...payload
        }
      )
      .then(response => {
        commit("setNumbers", response.data.data);
      })
      .catch(() => {});

    commit("setLoading", false);
  }
  // async getNumbersByDni({ commit }, payload) {
  //   commit("setLoading", true);
  //   await Axios.post(
  //     "https://apidevprod.cuy.pe/api/v1/user/list-phone-by-dni-public",
  //     {
  //       ...payload
  //     },
  //     {
  //     headers: {
  //       'Content-type': 'application/json; charset=utf-8',
  //       'cuy-api-client': `{"cuyBrandID": 2, "platform":"web"}` 
  //     }
  //   }).then(response => {
  //       commit("setNumbers", response.data.data);
  //     })
  //     .catch(() => {});

  //   commit("setLoading", false);
  // }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  setTypes(state, types) {
    state.types = types;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setNumbers(state, data) {
    state.numbersList = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
