import * as fb from '../utils/firebase';
import toaster from 'toastr';

const state = {
    legales: []
};

const getters = {
    getLegales: (state) => {
        return state.legales.filter(item => item.status == "published")
    },
    getLegalesBySlug: (state) => (slug) => {
        return state.legales.find((item) => item.slug == slug)
    },
    getLegalesById: (state) => (id) => {
        return state.legales.find((item) => item.id == id)
    },
    getPublishedLegales: (state) => {
        return state.legales.filter(item => item.status == "published").length
    },
    getDraftLegales: (state) => {
        return state.legales.filter(item => item.status == "draft").length
    }
}

const mutations = {
    setLegales(state, val) {
        state.legales = val;
    }
};

const actions = {
    createLegales({ commit }, post) {
        console.log(commit, 'commit');

        return new Promise((resolve, reject) => {
            fb.legalesCollection.add({
                created_at: new Date(),
                body: post.body,
                title: post.title,
                user_id: fb.auth.currentUser.uid,
                slug: post.slug,
                hasBody: post.hasBody,
                status: post.status,
                meta_description: post.meta_description,
                meta_keywords: post.meta_keywords,
                menu: post.menu,
                icon: post.icon,
                order: post.order
            })
                .then(docRef => {
                    toaster.success('Created successfully', 'Success');
                    resolve(docRef.id)
                })
                .catch(error => reject(error));
        })
    },
    async updateLegales({ commit }, post) {
        console.log(commit, 'commit');

        return new Promise((resolve, reject) => {
            fb.legalesCollection.doc(post.id).update({
                created_at: new Date(),
                body: post.body,
                title: post.title,
                subtitle: post.subtitle || "",
                user_id: fb.auth.currentUser.uid,
                slug: post.slug,
                hasBody: post.hasBody,
                status: post.status,
                meta_description: post.meta_description,
                meta_keywords: post.meta_keywords,
                menu: post.menu,
                icon: post.icon,
                order: post.order
            })
                .then(function () {
                    toaster.success('Updated successfully', 'Success');
                    resolve(true);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    async swapLegales({ commit }, post) {
        console.log(commit, 'commit');
        const batch = fb.db.batch();

        const post_a = fb.legalesCollection.doc(post.a.id);
        batch.update(post_a, { order: post.a.order });

        const post_b = fb.legalesCollection.doc(post.b.id);
        batch.update(post_b, { order: post.b.order });

        await batch.commit();
    },
    deleteLegales({ commit }, post) {
        console.log(commit);
        return new Promise((resolve, reject) => {
            fb.legalesCollection.doc(post.id).delete().then(function () {
                resolve("Document successfully deleted!");
            }).catch(error => reject(error));
        });
    }
}

export default {
    state,
    getters,
    actions,
    mutations
};
