const state = {
  selectedIndex: "1"
};

const getters = {
  selectedIndex(state) {
    return state.selectedIndex;
  }
};

const actions = {};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  changeIndex(state, index) {
    state.selectedIndex = index;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
